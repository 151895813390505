/* @import url("datepicker.css"); */

html, #root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* TODO@jkica: this should be moved to the theme file */
  font: 13px/1.4 Roboto, sans-serif;
  box-sizing: border-box;
}
/* TODO@jkica: figure out a way to register fonts in the theme file, atm the issue is the old MUI version */
@font-face {
  font-family: "GothicArabicBold";
    src: url('assets/fonts/tauroMarkets/arabic/GothicArabicBold.ttf') format('truetype');
}
@font-face {
  font-family: "GothicArabicHeavy";
    src: url('assets/fonts/tauroMarkets/arabic/GothicArabicHeavy.ttf') format('truetype');
}
a {
  text-decoration: none;
  color: inherit;
  font-weight: initial;
}
strong{
  font-weight:500
}

div#prefixGrid span.flag-icon-lg, div#countryGrid span.flag-icon-lg{
  font-size: 1em
}

.css-d8oujb{
  display: none
}

[class*="indicatorSeparator"]{
  display: none
}

.css-1ep9fjw, .css-1uq0kb5{
  padding: 8px 1.5px !important;
}

.recurly-hosted-field{
   border-radius: 0;
   border-top: 0;
   border-left: 0;
   border-right: 0;
   padding: 0;
   min-height: 2.5rem;
}

.recurly-hosted-field-icon{
  margin-left:0;
  padding:5px;
}
.recurly-hosted-field-number {
  width: 100%;
}
.recurly-hosted-field.recurly-hosted-field-month {
  width: 90%;
}
.recurly-hosted-field.recurly-hosted-field-year{
  width: 90%;
}
.recurly-hosted-field.recurly-hosted-field-cvv{
  width: 100%;
}
#three-d-container > div {
  height: 100%;
}

.highlightText{
font-weight: 400;
}

.italicText{
  font-style: italic
}

.loadingApp #toggleTheme{
  display:none;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

.sfcModal-dialog {
  height: 100%;
}
.sfcModal-content {
  height: 100%;
}
.sfcModal-body {
  height: 100%;
}
.sfcModal-body iframe {
  height: 100% !important;
}

.livechat-bubble {
  margin-bottom: 50px;
  margin-right: 15px;
  min-width: 84px !important;
  min-height: 84px !important;
}

.displayNone {
  display: none
}

.dashboard-image{
  width: 75% !important;
  height: unset !important;
  max-height: 600px;
  display: block;
  margin: 0 auto;
}

em.cbc_content {
  font-style: normal;
}

@media only screen and (max-width: 600px) {
  .rccs__card{
    height: 148.73px;
    width: 235px;
  }
  .rccs__name{
    font-size:13px
  }
  .rccs__number{
    font-size:17px
  }
}

#fee-structure-tooltip {
  & .MuiTooltip-arrow {
    color: rgba(97, 97, 97, 0.90);
  }

  & .MuiTooltip-tooltip {
    background-color: rgba(97, 97, 97, 0.90);
  }
}